export var getUser = function getUser() {
  // get user
  var userObj = localStorage.getItem('userInfo');
  return JSON.parse(userObj);
};
export var setUser = function setUser(obj) {
  return localStorage.setItem('userInfo', JSON.stringify(obj));
};
export var deleteUser = function deleteUser() {
  return localStorage.removeItem('userInfo');
};