import Shimmer from "../public/src/components/Shimmer/Shimmer.js";
import isLogin from "./auth/isLogin.js";
import getNamespace from "./helpers/getNamspace.js";
import Preloader from "../public/src/components/preloader/Preloader.js";
var Main = function Main() {
  var shimmer = new Shimmer();
  var loader = new Preloader();
  loader.render($(".frame"), {
    position: "fixed",
    width: "calc(100% - 275px)",
    height: "calc(100% - 60px)"
  });
  // this will affect to all pages
  Barba.hooks.beforeEnter(function () {
    // presets
    shimmer.hide();
    // show sidemenu
    $(".frame").fadeIn(1000);

    // presets
    import("./helpers/presets.js");
    if (getNamespace().namespace != "login") {
      isLogin(getNamespace().namespace).then(function (res) {
        if (!res.success) {
          location.href = "http://localhost:8080";
        }
      });
    }
  });
  Barba.init({
    debug: true,
    /* to prevent multiple click */
    prevent: function prevent(_ref) {
      var el = _ref.el,
        event = _ref.event;
      if (event.type === "click") {
        event.preventDefault();
      }
    },
    views: [{
      namespace: "admin",
      beforeEnter: function beforeEnter() {
        import("./Admin.js").then(function (x) {
          x.Admin(loader);
        });
      }
    }, {
      namespace: "contract",
      beforeEnter: function beforeEnter() {
        import("./Contract.js").then(function (x) {
          x.Contract(loader);
        });
      }
    }, {
      namespace: "sales",
      beforeEnter: function beforeEnter() {
        import("./Sales.js").then(function (x) {
          x.Sales();
        });
      }
    }, {
      namespace: "quotation",
      beforeEnter: function beforeEnter() {
        import("./Quotation.js").then(function (x) {
          x.Quotation(loader);
        });
      }
    }, {
      namespace: "Accounts",
      beforeEnter: function beforeEnter() {
        import("./Accounts.js").then(function (x) {
          x.Accounts(loader);
        });
      }
    }]
  });
};
export default Main;