import docCookies from "./docCookies.js";
var cookies = function () {
  function add(props) {
    var cookieName = props.cookieName,
      data = props.data,
      key = props.key;
    if (!docCookies) return;
    if (!docCookies.getItem(cookieName)) {
      var stringData = JSON.stringify(addcookieId(data, key));
      docCookies.setItem(cookieName, stringData, undefined, '/');
    } else if (docCookies.getItem(cookieName)) {
      var currentData = docCookies.getItem(cookieName);
      var toJson = JSON.parse('[' + currentData + ']');
      var isAvaialbe = toJson.filter(function (item) {
        return item.key == key;
      });
      if (isAvaialbe == "") {
        var _stringData = JSON.stringify(addcookieId(data, key));
        var mergeNewData = currentData + ',' + _stringData;
        docCookies.setItem(cookieName, mergeNewData, undefined, '/');
      } else {
        var index;
        var existingData = toJson.map(function (item, i) {
          if (item.key == key) {
            index = i;
          } else {
            return item;
          }
        });
        existingData = existingData.filter(function (x) {
          return x != undefined;
        });
        existingData.splice(index, 0, addcookieId(data, key));
        var finalData = "";
        existingData.map(function (item, i) {
          finalData += JSON.stringify(item);
          finalData += existingData.length == i + 1 ? '' : ',';
        });
        docCookies.setItem(cookieName, finalData, undefined, '/');
      }
    }
  }
  function remove(props) {
    var cookieName = props.cookieName,
      key = props.key;
    if (docCookies.getItem(cookieName)) {
      var currentCookieData = JSON.parse('[' + docCookies.getItem(cookieName) + ']');
      var slicedItem = currentCookieData.filter(function (item) {
        return item.key !== key;
      });
      var cookieNewData = '';
      slicedItem.forEach(function (item, i) {
        if (slicedItem.length === 1 || slicedItem.length === i + 1) {
          cookieNewData += JSON.stringify(item);
        } else {
          cookieNewData += JSON.stringify(item) + ',';
        }
      });
      if (cookieNewData) {
        docCookies.setItem(cookieName, cookieNewData, undefined, '/');
      } else {
        // Set the cookie with an empty value and an expired date to remove it
        docCookies.setItem(cookieName, '', new Date(0), '/');
      }
    }
  }
  function getData(para) {
    if (para) {
      var retriveExistingData = docCookies.getItem(para);
      return JSON.parse('[' + retriveExistingData + ']');
    }
  }
  function addcookieId(data, key) {
    var retriveData = data;
    retriveData.key = key;
    return retriveData;
  }
  return {
    add: add,
    remove: remove,
    getData: getData
  };
}();
export default cookies;

// USAGE

//---------------------------
// To add item to cookies
// Ecookies.add({
//     cookieName : 'mycookies',
//     key : 1,
//     data : {
//         id : 1,
//         name : 'hello',
//         price : 500
//     }
// })

//---------------------------
// To remove Item from cookies
// Ecookies.remove({
//     cookieName : 'mycookies',
//     key : 1
// })

//----------------------------
// Tog getValue
// Ecookies.getData('mycookies');